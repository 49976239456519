export interface IBaseAPIResp {
  success: boolean
  responseMessage: string
  data: any[]
}
export interface IGetDueDiligenceAudit {
  rcm_sec_id: string
  approvalStatus: string
  approvalEffectiveDate: Date
  selectList: boolean
  selectListEffectiveDate: Date
  updatedBy: string
  updatedDate: Date
}

export interface IUpdateDueDiligenceApiRes {
  errorCode?: number
  errormessage?: string
  outputResponse?: string
  msg: string
}

export interface IAuditRequestByRcmSecId {
  rcm_sec_id: string
  columnName: string
  newEffectiveDate: Date
  newValue: string
  oldValue: string
  oldEffectiveDate: Date
  updatedBy: string
  updatedDate: Date
}

export interface IAuditRequestByRcmSecIdApiRes extends IBaseAPIResp {
  data: IAuditRequestByRcmSecId[]
}

export interface IUpdateDueDiligence {
  rcm_sec_id: string
  approvalStatus?: string
  approvalEffectiveDate?: Date
  selectList?: string
  selectListEffectiveDate?: Date
}
export interface IGetDueDiligence {
  rcm_sec_id: string
  approvalStatus: string
  approvalEffectiveDate?: Date
  selectList: string
  selectListEffectiveDate?: Date
  createdBy: string
  createdDate: Date
  updatedBy: string
  updatedDate: string
  // audits: IGetDueDiligenceAudit[]
}
export interface IGetDueDiligenceApiRes extends IBaseAPIResp {
  data: IGetDueDiligence[]
}

export enum DueDiligenceColumnTypes {
  approvalStatus = 'approvalStatus',
  approvalEffectiveDate = 'approvalEffectiveDate',
  selectList = 'selectList',
  selectListEffectiveDate = 'selectListEffectiveDate',
  updatedDate = 'updatedDate'
}
export enum ApprovalStatus {
  APRVD = 'Approved',
  APRVDH = 'Approved to hold',
  NOTAPRVD = 'Not Approved',
  APRVDRI = 'Approved - Reverse Inquiry'
}

export interface ISelecedOptions {
  rcm_sec_id: string
  cusip?: string
  isin?: string
  sedol?: string
  symbol?: string
  createSource?: string
  sectorCode?: string
  shortName?: string
  sectorDescription?: string
  secMasterDescription?: string
  assetTypeCode?: string
  assetTypeDescription?: string
  assetSubTypeCode?: string
  assetSubTypeDescription?: string
  l1Code?: string
  l1Description?: string
  l2Code?: string
  l2Description?: string
  l3Code?: string
  l3Description?: string
  l4Code?: string | null
  l4Description?: string | null
  actionCode?: string
  description?: string
  userid?: string
  lastUpdatedBy?: string
  lastUpdatedDate?: string
  updateSource?: string
  createdBy?: string
  createdDate?: string
}
