import axios, { AxiosError } from 'axios'
import { trim } from 'lodash'
import { flow } from 'lodash/fp'
import { isNotNullOrEmpty } from 'shared/guards'
import { IApiOptions } from '../shared/contracts/IApiOptions'
import { IAccount } from './account.types'
import { IAdvisorDetailResult } from './advisor.types'
import { IClient } from './client.types'
import {
  ISearchParams,
  ISearchResult,
  SearchIndices,
  SearchResponseType
} from './common.types'
//import { IDueDiligence } from './duediligence.types'
import { IServicePrincipal } from './graph'
import { IHousehold } from './household.types'
import { constructFilterQuery } from './odata'
import { IOrder } from './order.types'
import { IPerformanceReport } from './performancereport.types'
import { IPosition } from './position.types'
import { ISecurity } from './security.types'
import { IThirdPartyAdvisor } from './thirdpartyadvisor.type'
import { IThirdPartyFirm } from './thirdpartyfirm.type'
import { IThirdPartyProduct } from './thirdpartyproduct.type'

export const queryEscapeRegex = /([!*+&|()[\]{}^~?:"%#/<>\\`;=$.-])/g
export const escapeAndEncodeQuery: (query: string) => string =
  // doesn't look like special chars get indexed, so lets just remove them
  // query.replace(queryEscapeRegex, '\\$1')
  flow(
    (x) => x.replace(queryEscapeRegex, ' '),
    trim,
    (x) => x.split(/ +/).filter(Boolean).map(encodeURIComponent).join(' ')
  )

// export const escapeFilterValue = (filterValue: string) => {

// attribute = attribute.replace(/'/g, "''");

//      attribute = attribute.replace(/"+"/g, "%2B");
//      attribute = attribute.replace(/\//g, "%2F");
//      attribute = attribute.replace(/"?"/g, "%3F");
//      attribute = attribute.replace(/%/g, "%25");
//      attribute = attribute.replace(/#/g, "%23");
//      attribute = attribute.replace(/&/g, "%26");
// }

export const tokenizeQuery = (text = '') =>
  text.replace(queryEscapeRegex, ' ').split(/[ ,]+/).map(trim).filter(Boolean)

export const operators = {
  and: ` AND `,
  or: ` OR `
}

export const constructQuery = (query: string) =>
  tokenizeQuery(query)
    .map(escapeAndEncodeQuery)
    .filter(Boolean)
    .map((x) => `${x}*`)
    .join(operators.and)

export const constructOdataQueryFromSearchParams = (params: ISearchParams) => {
  const query = params.query
    ? params.exact
      ? escapeAndEncodeQuery(params.query)
      : constructQuery(params.query)
    : null

  const searchFilter = params.searchFilters
    ? params.searchFilters
        .map((filter) => {
          const searchQuery = filter.regex
            ? decodeURIComponent(filter.query)
            : `(${constructQuery(filter.query)})`

          return `${filter.dataPath}:${searchQuery}`
        })
        .join(operators.and)
    : null

  const searchText = [searchFilter, query].filter(Boolean).join(operators.and)

  return [
    searchText ? `search=${searchText}` : null,
    params.fullQuery ? 'queryType=full' : null,
    params.searchFields && params.searchFields.length && query && query.length
      ? `searchFields=${params.searchFields.map(encodeURIComponent).join(',')}`
      : null,
    params.select && params.select.length
      ? `select=${params.select.map(encodeURIComponent).join(',')}`
      : null,
    params.orderBy && params.orderBy.length
      ? `$orderby=${params.orderBy
          .map((x) => [x.dataPath, x.direction].filter((y) => y).join(' '))
          .join(',')}`
      : null,
    params.count ? '$count=true' : null,
    params.skip ? `$skip=${params.skip}` : null,
    `$top=${params.top ?? 1000}`,
    params.filters?.length
      ? `$filter=${constructFilterQuery(params.filters)}`
      : null,
    params.facets?.length
      ? params.facets.map((x) => `facet=${encodeURIComponent(x)}`).join('&')
      : null
  ]
    .filter(Boolean)
    .join('&')
}

export const constructOdataPostFromSearchParams = (params: ISearchParams) => {
  const query = params.query
    ? params.exact
      ? escapeAndEncodeQuery(params.query)
      : constructQuery(params.query)
    : null

  const searchFilter = params.searchFilters
    ? params.searchFilters
        .map((filter) => {
          const searchQuery = filter.regex
            ? decodeURIComponent(filter.query)
            : `(${constructQuery(filter.query)})`

          return `${filter.dataPath}:${searchQuery}`
        })
        .join(operators.and)
    : null

  const searchText = [searchFilter, query].filter(Boolean).join(operators.and)

  const postBody = {
    search: searchText ? decodeURIComponent(searchText) : undefined,
    queryType: params.fullQuery ? 'full' : undefined,
    searchFields: params.searchFields?.join(','),
    select: params.select?.join(','),
    orderby: params.orderBy
      ?.map((x) => [x.dataPath, x.direction].filter(isNotNullOrEmpty).join(' '))
      .join(','),
    count: params.count,
    skip: params.skip,
    top: params.top ?? 1000,
    filter:
      params.filters &&
      flow(constructFilterQuery, decodeURIComponent)(params.filters),
    facets: params.facets
  }

  return postBody
}

export function search(
  index: 'account',
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<IAccount>>
export function search(
  index: 'client',
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<IClient>>
export function search(
  index: 'advisor',
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<IAdvisorDetailResult>>
export function search(
  index: 'applications',
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<IServicePrincipal>>
export function search(
  index: 'position',
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<IPosition>>
export function search(
  index: 'thirdpartyadvisor',
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<IThirdPartyAdvisor>>
export function search(
  index: 'thirdpartyproduct',
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<IThirdPartyProduct>>
export function search(
  index: 'thirdpartyfirm',
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<IThirdPartyFirm>>
export function search(
  index: 'order',
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<IOrder>>
export function search(
  index: 'household',
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<IHousehold>>
export function search(
  index: 'security',
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<ISecurity>>
// export function search(
//   index: 'duediligences',
//   params: ISearchParams,
//   options: IApiOptions
// ): Promise<ISearchResult<IDueDiligence>>
export function search(
  index: 'genreport',
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<IPerformanceReport>>
export function search<T extends SearchResponseType>(
  index: SearchIndices,
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<T>>
export function search(
  index: SearchIndices,
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<SearchResponseType>> {
  let apiPath = ''
  switch (index) {
    case 'account':
      apiPath = 'accounts'
      break
    case 'advisor':
      apiPath = 'advisors'
      break
    case 'client':
      apiPath = 'clients'
      break
    case 'position':
      apiPath = 'positions'
      break
    case 'order':
      apiPath = 'orders'
      break
    case 'household':
      apiPath = 'households'
      break
    case 'creditevent':
      apiPath = 'creditevents'
      break
    case 'security':
      apiPath = 'security'
      break
    // case 'duediligences':
    //   apiPath = 'duediligences'
    //   break
    case 'thirdpartyproduct':
      apiPath = 'thirdpartyproduct'
      break
    case 'genreport':
      apiPath = 'genreport'
      break
    case 'umataxlots':
      apiPath = 'umataxlots'
      break
  }

  const rootPath = `${options.apiRoot}/datahub/search/${apiPath}`
  const postBody = constructOdataPostFromSearchParams({
    ...params,
    fullQuery: true
  })

  return axios
    .post<ISearchResult<SearchResponseType>>(rootPath, postBody, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => {
      if (!x || !x.data) {
        throw new Error('Invalid response from the search API')
      }

      if (!x.data.value) {
        throw new Error('No search result value was returned')
      }

      return x.data
    })
    .catch((e) => {
      const axiosError = e as AxiosError<{ error?: Error }>
      const message = axiosError?.response?.data?.error?.message
      if (message) {
        throw new Error(message)
      }

      throw axiosError
    })
}

export function safeSearch(
  index: SearchIndices,
  params: ISearchParams,
  options: IApiOptions
) {
  return search(index, params, options).catch(() => [])
}
