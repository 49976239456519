import { IColumn } from '@fluentui/react'
import { ActionType, createAction } from 'typesafe-actions'
import {
  IFeeCharge,
  IFeeFilter,
  IFeeType,
  IFeeCategory,
  IFutureFeeCharge,
  IUpdateFeeAnnualAmountReq,
  IFeeScheduleFilter
} from '../api/types/feefilter.type'
import {
  IAssetLookupByRowResponse,
  IAssetLookupResponse,
  IFeeAssignment,
  IFeeDeleteAssignment,
  // ITiredAumFeesForm,
  IEditGroupResponse,
  IEditOverrideResponse,
  IFeeGroupListItem,
  IFeeOverrideListItem,
  IGroupRow,
  IFeeAccount,
  IAccountRootModel,
  ITrustAssignmentListingModel,
  ITrustActionModel
} from '../api/types/types'
import { IUpdateAccountFeeModel } from '../api/types/updateFeeType'
import { IFlatFeeScheduleModel } from './flatFeeScheduleTypes'

import {
  createNewFeeActions,
  fetchFeeAdvisorActions,
  fetchFeeBusinessSegmentTypeActions,
  fetchFeeCategoriesActions,
  fetchFeeCollectionTypeActions,
  fetchFeeFrequencyTypeActions,
  fetchAgencyFeeTypeActions,
  fetchGLAccountActions,
  fetchDepositAccountActions,
  fetchFeeLegalEntitiesActions,
  fetchFeeServiceTypeActions,
  fetchInvoiceFeeTypeActions,
  updateNewFeeActions
} from './scheduleFeeFormActions'
import {
  IScheduleInfo,
  IFeeDetails,
  IFeeError,
  INavigateToAccruedAmountRequest,
  IFeePlan,
  IFeePlanExportReq,
  IPilotFeaturesList,
  IUpdateAccrualReq,
  IGenerateInvoiceRequest,
  IFeeInvoices,
  ISendForCollectionRequest,
  FeeDetailsItemsResponse,
  IGLString,
  IGLStringReq,
  IFeeScheduleForm,
  IFeeOverrideForm,
  IInsertUpdateFeeBuildGroupRes,
  IFlagFeeSchedule,
  IFeeListDetail,
  IGwesReportExportReq,
  IFeeBilling,
  IFeeBillingExportReq,
  IPlatformFeeScheduleApiResp,
  IPlatformFeeScheduleExportReq,
  IUpdatePlatformFeeScheduleReq,
  IPlatFormFeeDetailsApiReq,
  IPlatFormFeeCalc
} from './types'
import {
  IUpdateFeeSchedule,
  IUpdateFeeScheduleResponseModel
} from './updateFlatFeeScheduleTypes'

export const FETCH_SCHEDULE_LIST = '@modules/@fees/FETCH_SCHEDULE_LIST'
export const FETCH_SCHEDULE_LIST_SUCCESS =
  '@modules/@fees/FETCH_SCHEDULE_LIST_SUCCESS'
export const FETCH_SCHEDULE_LIST_FAILURE =
  '@modules/@fees/FETCH_SCHEDULE_LIST_FAILURE'
export const SET_SELECTED_FEEID = `@modules/@fees/SET_SELECTED_FEEID`
export const VIEW_FEE = `@modules/@fees/VIEW_FEE`
export const DELETE_FEE = `@modules/@fees/DELETE_FEE`
export const EDIT_FEE = `@modules/@fees/EDIT_FEE`

export const FETCH_FEE_SCHEDULE_LIST = '@modules/@fees/FETCH_FEE_SCHEDULE_LIST'
export const FETCH_FEE_SCHEDULE_LIST_SUCCESS =
  '@modules/@fees/FETCH_FEE_SCHEDULE_LIST_SUCCESS'
export const FETCH_FEE_SCHEDULE_LIST_FAILURE =
  '@modules/@fees/FETCH_FEE_SCHEDULE_LIST_FAILURE'
export const DELETE_FEE_SCHEDULE_FAILURE =
  '@modules/@fees/DELETE_FEE_SCHEDULE_FAILURE'
export const SET_SELECTED_FEESCHEDULEID = `@modules/@fees/SET_SELECTED_FEESCHEDULEID`
export const VIEW_FEE_SCHEDULE = `@modules/@fees/VIEW_FEE_SCHEDULE`
export const DELETE_FEE_SCHEDULE = `@modules/@fees/DELETE_FEE_SCHEDULE`
export const EDIT_FEE_SCHEDULE = `@modules/@fees/EDIT_FEE_SCHEDULE`
export const UPDATE_FEE_SCHEDULE = `@modules/@fees/UPDATE_FEE_SCHEDULE`

export const FETCH_FEE_TYPES_REQUEST = `@modules/@fees/FETCH_FEE_TYPE`
export const FETCH_FEE_TYPES_SUCCESS = `@modules/@fees/FETCH_FEE_TYPE_SUCCESS`
export const FETCH_FEE_TYPES_FAILURE = `@modules/@fees/FETCH_FEE_TYPE_FAILURE`

export const FETCH_FEE_CATEGORY_TYPES_REQUEST = `@modules/@fees/FETCH_FEE_CATEGORY_TYPE_REQUEST`
export const FETCH_FEE_CATEGORY_TYPES_SUCCESS = `@modules/@fees/FETCH_FEE_CATEGORY_TYPE_SUCCESS`
export const FETCH_FEE_CATEGORY_TYPES_FAILURE = `@modules/@fees/FETCH_FEE_CATEGORY_TYPE_FAILURE`

export const FETCH_FEE_REQUEST = `@modules/@fees/FETCH_FEE_REQUEST`
export const FETCH_FEE_SUCCESS = `@modules/@fees/FETCH_FEE_SUCCESS`
export const FETCH_FEE_FAILURE = `@modules/@fees/FETCH_FEE_FAILURE`
export const RESET_FEE_DATA = `@modules/@fees/RESET_FEE_DATA`
export const NAVIGATE_BACK_TO_FEE = `@modules/@fees/NAVIAGTE_BACK_TO_FEE`
export const ADD_NEW_FEE = `@modules/@fees/ADD_NEW_FEE`
export const ADD_NEW_FEE_ERROR = `@modules/@fees/ADD_NEW_FEE_ERROR`
export const RESET_NEW_FEE = `@modules/@fees/RESET_NEW_FEE`

export const FETCH_PASTCHARGES_REQUEST = `@modules/@fees/FETCH_PASTCHARGES_REQUEST`
export const FETCH_PASTCHARGES_SUCCESS = `@modules/@fees/FETCH_PASTCHARGES_SUCCESS`
export const FETCH_PASTCHARGES_FAILURE = `@modules/@fees/FETCH_PASTCHARGES_FAILURE`

export const FETCH_FUTURECHARGES_REQUEST = `@modules/@fees/FETCH_FUTURECHARGES_REQUEST`
export const FETCH_FUTURECHARGES_SUCCESS = `@modules/@fees/FETCH_FUTURECHARGES_SUCCESS`
export const FETCH_FUTURECHARGES_FAILURE = `@modules/@fees/FETCH_FUTURECHARGES_FAILURE`

export const CHARGE_FEE_REQUEST = `@modules/@fees/CHARGE_FEE_REQUEST`
export const CHARGE_FEE_SUCCESS = `@modules/@fees/CHARGE_FEE_SUCCESS`
export const CHARGE_FEE_FAILURE = `@modules/@fees/CHARGE_FEE_FAILURE`

export const EXPORT_SCHEDULED_FEE_REQUEST = `@modules/@fees/EXPORT_SCHEDULED_FEE_REQUEST`
export const EXPORT_SCHEDULED_FEE_SUCCESS = `@modules/@fees/EXPORT_SCHEDULED_FEE_SUCCESS`
export const EXPORT_SCHEDULED_FEE_FAILURE = `@modules/@fees/EXPORT_SCHEDULED_FEE_FAILURE`

export const NAVIGATE_TO_ACCRUED_AMOUNT = `@modules/@fees/NAVIGATE_TO_ACCRUED_AMOUNT`
export const NAVIGATE_TO_FEE_DASHBOARD = `@modules/@fees/NAVIGATE_TO_FEE_DASHBOARD`
export const SET_SELECTED_FEE_PLAN = `@modules/@fees/SET_SELECTED_FEE_PLAN`

export const FEE_DASHBOARD_REQUEST = `@modules/@fees/FEE_DASHBOARD_REQUEST`
export const FEE_DASHBOARD_SUCCESS = `@modules/@fees/FEE_DASHBOARD_SUCCESS`
export const FEE_DASHBOARD_FAILURE = `@modules/@fees/FEE_DASHBOARD_FAILURE`

export const PLATFORM_FEE_SCHEDULE_REQUEST = `@modules/@fees/PLATFORM_FEE_SCHEDULE_REQUEST`
export const PLATFORM_FEE_SCHEDULE_SUCCESS = `@modules/@fees/PLATFORM_FEE_SCHEDULE_SUCCESS`
export const PLATFORM_FEE_SCHEDULE_FAILURE = `@modules/@fees/PLATFORM_FEE_SCHEDULE_FAILURE`

export const PLATFORM_FEE_DETAIL_REQUEST = `@modules/@fees/PLATFORM_FEE_DETAIL_REQUEST`
export const PLATFORM_FEE_DETAIL_SUCCESS = `@modules/@fees/PLATFORM_FEE_DETAIL_SUCCESS`
export const PLATFORM_FEE_DETAIL_FAILURE = `@modules/@fees/PLATFORM_FEE_DETAIL_FAILURE`
export const PLATFORM_FEE_DETAIL_CLEAR = `@modules/@fees/PLATFORM_FEE_DETAIL_CLEAR`

export const PLATFORM_FEE_CALC_PERIOD_REQUEST = `@modules/@fees/PLATFORM_FEE_CALC_PERIOD_REQUEST`
export const PLATFORM_FEE_CALC_PERIOD_SUCCESS = `@modules/@fees/PLATFORM_FEE_CALC_PERIOD_SUCCESS`
export const PLATFORM_FEE_CALC_PERIOD_FAILURE = `@modules/@fees/PLATFORM_FEE_CALC_PERIOD_FAILURE`

export const UPDATE_PLATFORM_FEE_SCHEDULE_REQUEST = `@modules/@fees/UPDATE_PLATFORM_FEE_SCHEDULE_REQUEST`
export const UPDATE_PLATFORM_FEE_SCHEDULE_SUCCESS = `@modules/@fees/UPDATE_PLATFORM_FEE_SCHEDULE_SUCCESS`
export const UPDATE_PLATFORM_FEE_SCHEDULE_FAILURE = `@modules/@fees/UPDATE_PLATFORM_FEE_SCHEDULE_FAILURE`

export const EXPORT_PLATFORM_FEE_SCHEDULE = `@modules/@fees/EXPORT_PLATFORM_FEE_SCHEDULE`
export const EXPORT_PLATFORM_FEE_SCHEDULE_SUCCESS = `@modules/@fees/EXPORT_PLATFORM_FEE_SCHEDULE_SUCCESS`
export const EXPORT_PLATFORM_FEE_SCHEDULE_FAILURE = `@modules/@fees/EXPORT_PLATFORM_FEE_SCHEDULE_FAILURE`

export const FEE_BILLING_DASHBOARD_REQUEST = `@modules/@fees/FEE_BILLING_DASHBOARD_REQUEST`
export const FEE_BILLING_DASHBOARD_SUCCESS = `@modules/@fees/FEE_BILLING_DASHBOARD_SUCCESS`
export const FEE_BILLING_DASHBOARD_FAILURE = `@modules/@fees/FEE_BILLING_DASHBOARD_FAILURE`

export const RUN_BILLING = `@modules/@fees/RUN_BILLING`
export const RUN_BILLING_SUCCESS = `@modules/@fees/RUN_BILLING_SUCCESS`
export const RUN_BILLING_FAILURE = `@modules/@fees/RUN_BILLING_FAILURE`

export const SET_SELECTED_MONTH_YEAR = `@modules/@fees/SET_SELECTED_MONTH_YEAR`
export const SET_FEE_BILLING_SELECTED_MONTH_YEAR = `@modules/@fees/SET_FEE_BILLING_SELECTED_MONTH_YEAR`

export const FETCH_ACCRUALS_REQUEST = `@modules/@fees/FETCH_ACCRUALS_REQUEST`
export const FETCH_ACCRUALS_SUCCESS = `@modules/@fees/FETCH_ACCRUALS_SUCCESS`
export const FETCH_ACCRUALS_FAILURE = `@modules/@fees/FETCH_ACCRUALS_FAILURE`

export const EXPORT_FEE_PLANS = `@modules/@fees/EXPORT_FEE_PLANS`
export const EXPORT_FEE_PLANS_SUCCESS = `@modules/@fees/EXPORT_FEE_PLANS_SUCCESS`
export const EXPORT_FEE_PLANS_FAILURE = `@modules/@fees/EXPORT_FEE_PLANS_FAILURE`

export const EXPORT_EXCEL_GWES_REPORT = `@modules/@fees/EXPORT_EXCEL_GWES_REPORT`
export const EXPORT_EXCEL_GWES_REPORT_SUCCESS = `@modules/@fees/EXPORT_EXCEL_GWES_REPORT_SUCCESS`
export const EXPORT_EXCEL_GWES_REPORT_FAILURE = `@modules/@fees/EXPORT_EXCEL_GWES_REPORT`

export const EXPORT_FEE_BILLING = `@modules/@fees/EXPORT_FEE_BILLING`
export const EXPORT_FEE_BILLING_SUCCESS = `@modules/@fees/EXPORT_FEE_BILLING_SUCCESS`
export const EXPORT_FEE_BILLING_FAILURE = `@modules/@fees/EXPORT_FEE_BILLING_FAILURE`

export const FETCH_PILOT_FEATURE = '@modules/@fees/ FETCH_PILOT_FEATURE'
export const FETCH_PILOT_FEATURE_SUCCESS =
  '@modules/@fees/FETCH_PILOT_FEATURE_SUCCESS'
export const FETCH_PILOT_FEATURE_FAILURE =
  '@modules/@fees/FETCH_PILOT_FEATURE_FAILURE'

export const UPDATE_ACCRUALS = `@modules/@fees/UPDATE_ACCRUALS`
export const UPDATE_ACCRUALS_SUCCESS = `@modules/@fees/UPDATE_ACCRUALS_SUCCESS`
export const UPDATE_ACCRUALS_FAILURE = `@modules/@fees/UPDATE_ACCRUALS_FAILURE`

export const VIEW_INVOICE = `@modules/@fees/VIEW_INVOICE`
export const VIEW_INVOICE_SUCCESS = `@modules/@fees/VIEW_INVOICE_SUCCESS`
export const VIEW_INVOICE_FAILURE = `@modules/@fees/VIEW_INVOICE_FAILURE`

export const GENERATE_INVOICE = `@modules/@fees/GENERATE_INVOICE`
export const GENERATE_INVOICE_SUCCESS = `@modules/@fees/GENERATE_INVOICE_SUCCESS`
export const GENERATE_INVOICE_FAILURE = `@modules/@fees/GENERATE_INVOICE_FAILURE`
export const GENERATE_INVOICE_CLEAR = `@modules/@fees/GENERATE_INVOICE_CLEAR`

export const SEND_FOR_COLLECTION = `@modules/@fees/SEND_FOR_COLLECTION`
export const SEND_FOR_COLLECTION_SUCCESS = `@modules/@fees/SEND_FOR_COLLECTION_SUCCESS`
export const SEND_FOR_COLLECTION_FAILURE = `@modules/@fees/SEND_FOR_COLLECTION_FAILURE`

export const CLOSE_MONTH = `@modules/@fees/CLOSE_MONTH`
export const CLOSE_MONTH_SUCCESS = `@modules/@fees/CLOSE_MONTH_SUCCESS`
export const CLOSE_MONTH_FAILURE = `@modules/@fees/CLOSE_MONTH_SUCCESS`

export const GET_INVOICES_REQUEST = `@modules/@fees/GET_INVOICES`
export const GET_INVOICES_SUCCESS = `@modules/@fees/GET_INVOICES_SUCCESS`
export const GET_INVOICES_FAILURE = `@modules/@fees/GET_INVOICES_FAILURE`
export const DELETE_INVOICE = '@modules/@fees/DELETE_INVOICE'

export const DOWNLOAD_D365_EXCEL = '@modules/@fees/DOWNLOAD_D365_EXCEL'
export const DOWNLOAD_D365_EXCEL_SUCCESS =
  '@modules/@fees/DOWNLOAD_D365_EXCEL_SUCCESS'
export const DOWNLOAD_D365_EXCEL_FAILURE =
  '@modules/@fees/DOWNLOAD_D365_EXCEL_FAILURE'
export const CLEAR_DOWNLOAD_D365_EXCEL =
  '@modules/@fees/CLEAR_DOWNLOAD_D365_EXCEL'

export const UPDATE_AMOUNT = `@modules/@fees/UPDATE_AMOUNT`
export const UPDATE_AMOUNT_SUCCESS = `@modules/@fees/UPDATE_AMOUNT_SUCCESS`
export const UPDATE_AMOUNT_FAILURE = `@modules/@fees/UPDATE_AMOUNT_FAILURE`
export const UPDATE_AMOUNT_CLEAR = `@modules/@fees/UPDATE_AMOUNT_CLEAR`

export const FETCH_INVOICE_DETAILITEMS = `@modules/@fees/FETCH_INVOICE_DETAILITEMS`
export const FETCH_INVOICE_DETAILITEMS_SUCCESS = `@modules/@fees/FETCH_INVOICE_DETAILITEMS_SUCCESS`
export const FETCH_INVOICE_DETAILITEMS_FAILURE = `@modules/@fees/FETCH_INVOICE_DETAILITEMS_FAILURE`

export const FETCH_FEE_GLSTRING = `@modules/@fees/FETCH_FEE_GLSTRING`
export const FETCH_FEE_GLSTRING_SUCCESS = `@modules/@fees/FETCH_FEE_GLSTRING_SUCCESS`
export const FETCH_FEE_GLSTRING_FAILURE = `@modules/@fees/FETCH_FEE_GLSTRING_FAILURE`
export const FETCH_FEE_GLSTRING_CLEAR = `@modules/@fees/FETCH_FEE_GLSTRING_CLEAR`

export const feeListActions = {
  request: createAction(FETCH_SCHEDULE_LIST)<IFeeFilter | undefined>(),
  success: createAction(FETCH_SCHEDULE_LIST_SUCCESS)<IFeeDetails[]>(),
  failure: createAction(FETCH_SCHEDULE_LIST_FAILURE)<Error>(),
  setfeeid: createAction(SET_SELECTED_FEEID)<string>(),
  viewfee: createAction(VIEW_FEE)<string>(),
  deletefee: createAction(DELETE_FEE)<string>(),
  editfee: createAction(EDIT_FEE)<string>()
}
export const DELETE_FEE_ASSIGNMENT = `@modules/@fees/DELETE_FEE_ASSIGNMENT`
export const DELETE_FEE_ASSIGNMENT_SUCCESS = `@modules/@fees/DELETE_FEE_ASSIGNMENT_SUCCESS`
export const DELETE_FEE_ASSIGNMENT_FAILURE = `@modules/@fees/DELETE_FEE_ASSIGNMENT_FAILURE`
export const CLEAR_DELETE_FEE_ASSIGNMENT = `@modules/@fees/CLEAR_DELETE_FEE_ASSIGNMENT`

export const deleteFeeAssignmentActions = {
  request: createAction(DELETE_FEE_ASSIGNMENT)<IFeeDeleteAssignment>(),
  success: createAction(DELETE_FEE_ASSIGNMENT_SUCCESS)(),
  failure: createAction(DELETE_FEE_ASSIGNMENT_FAILURE)<Error>(),
  clear: createAction(CLEAR_DELETE_FEE_ASSIGNMENT)()
}
export const FETCH_FEE_ASSIGNMENT_LIST = `@modules/@fees/FETCH_FEE_ASSIGNMENT_LIST`
export const FETCH_FEE_ASSIGNMENT_LIST_SUCCESS = `@modules/@fees/FETCH_FEE_ASSIGNMENT_LIST_SUCCESS`
export const FETCH_FEE_ASSIGNMENT_LIST_FAILURE = `@modules/@fees/FETCH_FEE_ASSIGNMENT_LIST_FAILURE`
export const CLEAR_FETCH_FEE_ASSIGNMENT_LIST = `@modules/@fees/CLEAR_FETCH_FEE_ASSIGNMENT_LIST`
export const NAVIGATE_TO_ASSIGNMENT_FORM = `@modules/@fees/NAVIGATE_TO_ASSIGNMENT_FORM`

export const fetchFeeAssignmentDataActions = {
  request: createAction(FETCH_FEE_ASSIGNMENT_LIST)<string>(),
  success: createAction(FETCH_FEE_ASSIGNMENT_LIST_SUCCESS)<IFeeAssignment[]>(),
  failure: createAction(FETCH_FEE_ASSIGNMENT_LIST_FAILURE)<Error>(),
  clear: createAction(CLEAR_FETCH_FEE_ASSIGNMENT_LIST)(),
  navigateToAssignmentForm: createAction(NAVIGATE_TO_ASSIGNMENT_FORM)<{
    id: string
    status: string
    partyid: string
    mode?: string
  }>()
}

export const FETCH_TRUST_ASSIGNMENT_EXIST = `@modules/@fees/FETCH_TRUST_ASSIGNMENT_EXIST`
export const FETCH_TRUST_ASSIGNMENT_EXIST_SUCCESS = `@modules/@fees/FETCH_TRUST_ASSIGNMENT_EXIST_SUCCESS`
export const FETCH_TRUST_ASSIGNMENT_EXIST_CLEAR = `@modules/@fees/FETCH_TRUST_ASSIGNMENT_EXIST_CLEAR`
export const FETCH_TRUST_ASSIGNMENT_LIST = `@modules/@fees/FETCH_TRUST_ASSIGNMENT_LIST`
export const FETCH_TRUST_ASSIGNMENT_LIST_SUCCESS = `@modules/@fees/FETCH_TRUST_ASSIGNMENT_LIST_SUCCESS`
export const FETCH_TRUST_ASSIGNMENT_LIST_FAILURE = `@modules/@fees/FETCH_TRUST_ASSIGNMENT_LIST_FAILURE`
export const CLEAR_FETCH_TRUST_ASSIGNMENT_LIST = `@modules/@fees/CLEAR_FETCH_TRUST_ASSIGNMENT_LIST`
export const NAVIGATE_TO_TRUST_ASSIGNMENT_FORM = `@modules/@fees/NAVIGATE_TO_TRUST_ASSIGNMENT_FORM`

export const fetchTrustAssignmentDataActions = {
  trustExist: createAction(FETCH_TRUST_ASSIGNMENT_EXIST)<string>(),
  isTrustExistSuccess: createAction(FETCH_TRUST_ASSIGNMENT_EXIST_SUCCESS)<
    ITrustAssignmentListingModel[]
  >(),
  isTrustExistClear: createAction(FETCH_TRUST_ASSIGNMENT_EXIST_CLEAR)(),
  request: createAction(FETCH_TRUST_ASSIGNMENT_LIST)<string>(),
  success: createAction(FETCH_TRUST_ASSIGNMENT_LIST_SUCCESS)<
    ITrustAssignmentListingModel[]
  >(),
  failure: createAction(FETCH_TRUST_ASSIGNMENT_LIST_FAILURE)<Error>(),
  clear: createAction(CLEAR_FETCH_TRUST_ASSIGNMENT_LIST)(),
  navigateToTrustAssignmentPage: createAction(
    NAVIGATE_TO_TRUST_ASSIGNMENT_FORM
  )<{
    id: string
    status: string
    partyid: string
    mode?: string
  }>()
}

export const HANDLE_TRUST_ACTION = `@modules/@fees/HANDLE_TRUST_ACTION`
export const HANDLE_TRUST_ACTION_SUCCESS = `@modules/@fees/FETCH_TRUST_ASSIGNMENT_SUCCESS`
export const HANDLE_TRUST_ACTION_FAILURE = `@modules/@fees/FETCH_TRUST_ASSIGNMENT_FAILURE`
export const HANDLE_TRUST_ACTION_CLEAR = `@modules/@fees/FETCH_TRUST_ASSIGNMENT_CLEAR`

export const handleTrustActions = {
  request: createAction(HANDLE_TRUST_ACTION)<ITrustActionModel>(),
  success: createAction(
    HANDLE_TRUST_ACTION_SUCCESS
  )<ITrustAssignmentListingModel>(),
  failure: createAction(HANDLE_TRUST_ACTION_FAILURE)<Error>(),
  clear: createAction(HANDLE_TRUST_ACTION_CLEAR)()
}

export const INSERT_UPDATE_FEE_ASSIGNMENT = `@modules/@fees/INSERT_UPDATE_FEE_GROUP`
export const INSERT_UPDATE_FEE_ASSIGNMENT_SUCCESS = `@modules/@fees/INSERT_UPDATE_FEE_GROUP_SUCCESS`
export const INSERT_UPDATE_FEE_ASSIGNMENT_FAILURE = `@modules/@fees/INSERT_UPDATE_FEE_GROUP_FAILURE`
export const INSERT_UPDATE_FEE_ASSIGNMENT_CLEAR = `@modules/@fees/INSERT_UPDATE_FEE_GROUP_CLEAR`

export const insertUpdateFeeGroupActions = {
  request: createAction(INSERT_UPDATE_FEE_ASSIGNMENT)<string>(),
  success: createAction(
    INSERT_UPDATE_FEE_ASSIGNMENT_SUCCESS
  )<IEditGroupResponse>(),
  failure: createAction(INSERT_UPDATE_FEE_ASSIGNMENT_FAILURE)<Error>(),
  clear: createAction(INSERT_UPDATE_FEE_ASSIGNMENT_CLEAR)()
}
export const feeScheduleListActions = {
  request: createAction(FETCH_FEE_SCHEDULE_LIST)<
    IFeeScheduleFilter | undefined
  >(),
  success: createAction(FETCH_FEE_SCHEDULE_LIST_SUCCESS)<IFeeDetails[]>(),
  failure: createAction(FETCH_FEE_SCHEDULE_LIST_FAILURE)<Error>(),
  deleteFailure: createAction(DELETE_FEE_SCHEDULE_FAILURE)<any>(),
  setfeeschedule: createAction(SET_SELECTED_FEESCHEDULEID)<IFeeListDetail>(),
  viewfeeschedule: createAction(VIEW_FEE_SCHEDULE)<IFeeListDetail>(),
  deletefeeschedule: createAction(DELETE_FEE_SCHEDULE)<string>(),
  editfeeschedule: createAction(EDIT_FEE_SCHEDULE)<IFeeListDetail>(),
  updatefeeschedule: createAction(UPDATE_FEE_SCHEDULE)<IFlagFeeSchedule>()
}

export const feeTypeActions = {
  request: createAction(FETCH_FEE_TYPES_REQUEST)(),
  success: createAction(FETCH_FEE_TYPES_SUCCESS)<IFeeType[]>(),
  failure: createAction(FETCH_FEE_TYPES_FAILURE)<Error>()
}

export const feeCategoryTypeActions = {
  request: createAction(FETCH_FEE_CATEGORY_TYPES_REQUEST)(),
  success: createAction(FETCH_FEE_CATEGORY_TYPES_SUCCESS)<IFeeCategory[]>(),
  failure: createAction(FETCH_FEE_CATEGORY_TYPES_FAILURE)<Error>()
}

export const feeFormActions = {
  addfee: createAction(ADD_NEW_FEE)<IScheduleInfo>(),
  error: createAction(ADD_NEW_FEE_ERROR)<IFeeError>(),
  reset: createAction(RESET_NEW_FEE)<null>()
}

export const feeDataActions = {
  request: createAction(FETCH_FEE_REQUEST)(),
  success: createAction(FETCH_FEE_SUCCESS)<IFeeDetails>(),
  failure: createAction(FETCH_FEE_FAILURE)<Error>(),
  reset: createAction(RESET_FEE_DATA)(),
  navigateBackToFee: createAction(NAVIGATE_BACK_TO_FEE)()
}

export const feePastChargesActions = {
  request: createAction(FETCH_PASTCHARGES_REQUEST)(),
  success: createAction(FETCH_PASTCHARGES_SUCCESS)<IFeeCharge[]>(),
  failure: createAction(FETCH_PASTCHARGES_FAILURE)<Error>()
}

export const feeFutureChargesActions = {
  request: createAction(FETCH_FUTURECHARGES_REQUEST)(),
  success: createAction(FETCH_FUTURECHARGES_SUCCESS)<IFutureFeeCharge[]>(),
  failure: createAction(FETCH_FUTURECHARGES_FAILURE)<Error>()
}

export const chargeFeeActions = {
  request: createAction(CHARGE_FEE_REQUEST)<Date>(),
  success: createAction(CHARGE_FEE_SUCCESS)(),
  failure: createAction(CHARGE_FEE_FAILURE)<Error>()
}

export const exportScheduledFeeActions = {
  request: createAction(EXPORT_SCHEDULED_FEE_REQUEST)<{
    fees: IScheduleInfo[]
    columns: IColumn[]
  }>(),
  failure: createAction(EXPORT_SCHEDULED_FEE_FAILURE)<Error>(),
  success: createAction(EXPORT_SCHEDULED_FEE_SUCCESS)()
}
export const feeNavigationActions = {
  navigateToAccruedAmount: createAction(
    NAVIGATE_TO_ACCRUED_AMOUNT
  )<INavigateToAccruedAmountRequest>(),
  navigateToFeeDashboard: createAction(NAVIGATE_TO_FEE_DASHBOARD)()
}
export const setSelectedFeePlan = {
  selectedFeePlan: createAction(SET_SELECTED_FEE_PLAN)<
    IFeePlan | undefined | null
  >()
}
export const feeDashboardActions = {
  request: createAction(FEE_DASHBOARD_REQUEST)(),
  success: createAction(FEE_DASHBOARD_SUCCESS)<IFeePlan[]>(),
  failure: createAction(FEE_DASHBOARD_FAILURE)<Error>()
}

export const platformFeeScheduleActions = {
  request: createAction(PLATFORM_FEE_SCHEDULE_REQUEST)(),
  success: createAction(PLATFORM_FEE_SCHEDULE_SUCCESS)<
    IPlatformFeeScheduleApiResp | undefined
  >(),
  failure: createAction(PLATFORM_FEE_SCHEDULE_FAILURE)<Error>()
}

export const platformFeeDetailActions = {
  request: createAction(
    PLATFORM_FEE_DETAIL_REQUEST
  )<IPlatFormFeeDetailsApiReq>(),
  success: createAction(PLATFORM_FEE_DETAIL_SUCCESS)<
    IPlatFormFeeCalc | undefined
  >(),
  failure: createAction(PLATFORM_FEE_DETAIL_FAILURE)<Error>(),
  clear: createAction(PLATFORM_FEE_DETAIL_CLEAR)()
}

export const platformFeeCalcPeriodActions = {
  request: createAction(PLATFORM_FEE_CALC_PERIOD_REQUEST)(),
  success: createAction(PLATFORM_FEE_CALC_PERIOD_SUCCESS)<
    string[] | undefined
  >(),
  failure: createAction(PLATFORM_FEE_CALC_PERIOD_FAILURE)<Error>()
}

export const updatePlatformFeeScheduleActions = {
  request: createAction(
    UPDATE_PLATFORM_FEE_SCHEDULE_REQUEST
  )<IUpdatePlatformFeeScheduleReq>(),
  success: createAction(UPDATE_PLATFORM_FEE_SCHEDULE_SUCCESS)<string>(),
  failure: createAction(UPDATE_PLATFORM_FEE_SCHEDULE_FAILURE)<Error>()
}

export const exportplatformFeeScheduleToExcelActions = {
  request: createAction(
    EXPORT_PLATFORM_FEE_SCHEDULE
  )<IPlatformFeeScheduleExportReq>(),
  success: createAction(EXPORT_PLATFORM_FEE_SCHEDULE_SUCCESS)(),
  failure: createAction(EXPORT_PLATFORM_FEE_SCHEDULE_FAILURE)<Error>()
}

export const feeBillingDashboardActions = {
  request: createAction(FEE_BILLING_DASHBOARD_REQUEST)(),
  success: createAction(FEE_BILLING_DASHBOARD_SUCCESS)<
    IFeeBilling | undefined
  >(),
  failure: createAction(FEE_BILLING_DASHBOARD_FAILURE)<Error>()
}

export const runBillingActions = {
  request: createAction(RUN_BILLING)(),
  success: createAction(RUN_BILLING_SUCCESS)<string>(),
  failure: createAction(RUN_BILLING_FAILURE)<Error>()
}

export const setSelectedMonthYearActions = {
  selectedMonthYear: createAction(SET_SELECTED_MONTH_YEAR)<string>()
}

export const setFeeBillingSelectedMonthYearActions = {
  FeeBillingSelectedMonthYear: createAction(
    SET_FEE_BILLING_SELECTED_MONTH_YEAR
  )<string>()
}
export const fetchAccrualsActions = {
  request: createAction(FETCH_ACCRUALS_REQUEST)(),
  success: createAction(FETCH_ACCRUALS_SUCCESS)<IFeePlan[]>(),
  failure: createAction(FETCH_ACCRUALS_FAILURE)<Error>()
}
export const exportFeePlansToExcelActions = {
  request: createAction(EXPORT_FEE_PLANS)<IFeePlanExportReq>(),
  success: createAction(EXPORT_FEE_PLANS_SUCCESS)(),
  failure: createAction(EXPORT_FEE_PLANS_FAILURE)<Error>()
}

export const exportToExcelGwesReportActions = {
  request: createAction(EXPORT_EXCEL_GWES_REPORT)<IGwesReportExportReq>(),
  success: createAction(EXPORT_EXCEL_GWES_REPORT_SUCCESS)(),
  failure: createAction(EXPORT_EXCEL_GWES_REPORT_FAILURE)<Error>()
}

export const exportFeeBillingToExcelActions = {
  request: createAction(EXPORT_FEE_BILLING)<IFeeBillingExportReq>(),
  success: createAction(EXPORT_FEE_BILLING_SUCCESS)(),
  failure: createAction(EXPORT_FEE_PLANS_FAILURE)<Error>()
}

export const pilotFetaureActions = {
  request: createAction(FETCH_PILOT_FEATURE)(),
  success: createAction(FETCH_PILOT_FEATURE_SUCCESS)<IPilotFeaturesList>(),
  failure: createAction(FETCH_PILOT_FEATURE_FAILURE)<Error>()
}
export const UpdateAccrualsActions = {
  request: createAction(UPDATE_ACCRUALS)<IUpdateAccrualReq[]>(),
  success: createAction(UPDATE_ACCRUALS_SUCCESS)<boolean>(),
  failure: createAction(UPDATE_ACCRUALS_FAILURE)<Error>()
}
export const viewInvoiceActions = {
  request: createAction(VIEW_INVOICE)<IFeeInvoices>(),
  success: createAction(VIEW_INVOICE_SUCCESS)(),
  failure: createAction(VIEW_INVOICE_FAILURE)<Error>()
}
export const generateInvoiceActions = {
  request: createAction(GENERATE_INVOICE)<IGenerateInvoiceRequest[]>(),
  success: createAction(GENERATE_INVOICE_SUCCESS)<boolean>(),
  failure: createAction(GENERATE_INVOICE_FAILURE)<Error>(),
  clear: createAction(GENERATE_INVOICE_CLEAR)()
}

export const sendForCollectionActions = {
  request: createAction(SEND_FOR_COLLECTION)<ISendForCollectionRequest[]>(),
  success: createAction(SEND_FOR_COLLECTION_SUCCESS)(),
  failure: createAction(SEND_FOR_COLLECTION_FAILURE)<Error>()
}

export const closeMonthActions = {
  request: createAction(CLOSE_MONTH)(),
  success: createAction(CLOSE_MONTH_SUCCESS)<string>(),
  failure: createAction(CLOSE_MONTH_FAILURE)<Error>()
}

export const updateAmountActions = {
  request: createAction(UPDATE_AMOUNT)<IUpdateFeeAnnualAmountReq>(),
  success: createAction(UPDATE_AMOUNT_SUCCESS)<IFeeDetails[]>(),
  failure: createAction(UPDATE_AMOUNT_FAILURE)<Error>(),
  clear: createAction(UPDATE_AMOUNT_CLEAR)()
}

export const getInvoicesActions = {
  request: createAction(GET_INVOICES_REQUEST)(),
  success: createAction(GET_INVOICES_SUCCESS)<IFeeInvoices[]>(),
  failure: createAction(GET_INVOICES_FAILURE)<Error>(),
  deleteinvoice: createAction(DELETE_INVOICE)<string>()
}

export const downloadD365ExcelActions = {
  request: createAction(DOWNLOAD_D365_EXCEL)<string | undefined>(),
  success: createAction(DOWNLOAD_D365_EXCEL_SUCCESS)(),
  failure: createAction(DOWNLOAD_D365_EXCEL_FAILURE)<Error>(),
  clear: createAction(CLEAR_DOWNLOAD_D365_EXCEL)()
}

export const fetchInvoiceDetailItemsActions = {
  request: createAction(FETCH_INVOICE_DETAILITEMS)<string>(),
  success: createAction(
    FETCH_INVOICE_DETAILITEMS_SUCCESS
  )<FeeDetailsItemsResponse>(),
  failure: createAction(FETCH_INVOICE_DETAILITEMS_FAILURE)<Error>()
}

export const feeGLStringActions = {
  request: createAction(FETCH_FEE_GLSTRING)<IGLStringReq>(),
  success: createAction(FETCH_FEE_GLSTRING_SUCCESS)<IGLString>(),
  failure: createAction(FETCH_FEE_GLSTRING_FAILURE)<Error>(),
  clear: createAction(FETCH_FEE_GLSTRING_CLEAR)()
}

export const FETCH_FEE_SCHEDULE_REQUEST = `@modules/@fees/FETCH_FEE_SCHEDULE_REQUEST`
export const FETCH_FEE_SCHEDULE_SUCCESS = `@modules/@fees/FETCH_FEE_SCHEDULE_SUCCESS`
export const FETCH_FEE_SCHEDULE_FAILURE = `@modules/@fees/FETCH_FEE_SCHEDULE_FAILURE`
export const RESET_FEE_SCHEDULE_DATA = `@modules/@fees/RESET_FEE_SCHEDULE_DATA`
export const NAVIGATE_BACK_TO_FEE_SCHEDULE = `@modules/@fees/NAVIGATE_BACK_TO_FEE_SCHEDULE`

export const feeScheduleDataActions = {
  request: createAction(FETCH_FEE_SCHEDULE_REQUEST)(),
  success: createAction(FETCH_FEE_SCHEDULE_SUCCESS)<IFeeScheduleForm>(),
  failure: createAction(FETCH_FEE_SCHEDULE_FAILURE)<Error>(),
  reset: createAction(RESET_FEE_SCHEDULE_DATA)(),
  navigateBackToFeeSchedule: createAction(NAVIGATE_BACK_TO_FEE_SCHEDULE)()
}

export const INSERT_UPDATE_FEE_OVERRIDE = `@modules/@fees/INSERT_UPDATE_FEE_OVERRIDE`
export const INSERT_UPDATE_FEE_OVERRIDE_SUCCESS = `@modules/@fees/INSERT_UPDATE_FEE_OVERRIDE_SUCCESS`
export const INSERT_UPDATE_FEE_OVERRIDE_FAILURE = `@modules/@fees/INSERT_UPDATE_FEE_OVERRIDE_FAILURE`
export const INSERT_UPDATE_FEE_OVERRIDE_CLEAR = `@modules/@fees/INSERT_UPDATE_FEE_OVERRIDE_CLEAR`

export const insertUpdateFeeOverrideActions = {
  request: createAction(INSERT_UPDATE_FEE_OVERRIDE)<IFeeOverrideForm>(),
  success: createAction(INSERT_UPDATE_FEE_OVERRIDE_SUCCESS)(),
  failure: createAction(INSERT_UPDATE_FEE_OVERRIDE_FAILURE)<Error>(),
  clear: createAction(INSERT_UPDATE_FEE_OVERRIDE_CLEAR)()
}
export const INSERT_UPDATE_FEE_SCHEDULE = `@module/@fees/INSERT_UPDATE_FEE_SCHEDULE`
export const INSERT_UPDATE_FEE_SCHEDULE_SUCCESS = `@module/@fee/INSERT_UPDATE_FEE_SCHEDULE_SUCCESS`
export const INSERT_UPDATE_FEE_SCHEDULE_FAILURE = `@module/@fee/INSERT_UPDATE_FEE_SCHEDULE_FAILURE`
export const INSERT_UPDATE_FEE_SCHEDULE_CLEAR = `@module/@fee/INSERT_UPDATE_FEE_SCHEDULE_CLEAR`
export const insertUpdateFeeScheduleActions = {
  request: createAction(INSERT_UPDATE_FEE_SCHEDULE)<IFeeScheduleForm>(),
  success: createAction(INSERT_UPDATE_FEE_SCHEDULE_SUCCESS)<
    IFeeScheduleForm | undefined
  >(),
  failure: createAction(INSERT_UPDATE_FEE_SCHEDULE_FAILURE)<Error>(),
  clear: createAction(INSERT_UPDATE_FEE_SCHEDULE_CLEAR)()
}
export const FETCH_ASSET_SECURITY_DATA = `@modules/@fees/FETCH_ASSET_SECURITY_DATA`
export const FETCH_ASSET_SECURITY_DATA_SUCCESS = `@modules/@fees/FETCH_ASSET_SECURITY_DATA_SUCCESS`
export const FETCH_ASSET_SECURITY_DATA_FAILURE = `@modules/@fees/FETCH_ASSET_SECURITY_DATA_FAILURE`
export const FETCH_ASSET_SECURITY_DATA_CLEAR = `@modules/@fees/FETCH_ASSET_SECURITY_DATA_CLEAR`

export const fetchAssetSecurityDataActions = {
  request: createAction(FETCH_ASSET_SECURITY_DATA)<string>(),
  success: createAction(
    FETCH_ASSET_SECURITY_DATA_SUCCESS
  )<IAssetLookupResponse>(),
  failure: createAction(FETCH_ASSET_SECURITY_DATA_FAILURE)<Error>(),
  clear: createAction(FETCH_ASSET_SECURITY_DATA_CLEAR)()
}

export const FETCH_ASSET_SECURITY_DATA_PER_ROW = `@modules/@fees/FETCH_ASSET_SECURITY_DATA_PER_ROW`
export const FETCH_ASSET_SECURITY_DATA__PER_ROW_SUCCESS = `@modules/@fees/FETCH_ASSET_SECURITY_DATA_PER_ROW_SUCCESS`
export const FETCH_ASSET_SECURITY_DATA__PER_ROW_FAILURE = `@modules/@fees/FETCH_ASSET_SECURITY_DATA_PER_ROW_FAILURE`
export const FETCH_ASSET_SECURITY_DATA_PER_ROW_CLEAR = `@modules/@fees/FETCH_ASSET_SECURITY_DATA_PER_ROW_CLEAR`

export const fetchAssetSecurityDataPerRowActions = {
  request: createAction(FETCH_ASSET_SECURITY_DATA_PER_ROW)<{
    rowid: string
    searchString: string
  }>(),
  success: createAction(FETCH_ASSET_SECURITY_DATA__PER_ROW_SUCCESS)<
    IAssetLookupByRowResponse[]
  >(),
  failure: createAction(FETCH_ASSET_SECURITY_DATA__PER_ROW_FAILURE)<
    IAssetLookupByRowResponse[]
  >(),
  clear: createAction(FETCH_ASSET_SECURITY_DATA_PER_ROW_CLEAR)()
}

export const FETCH_SECURTIES = `@modules/@fees/FETCH_SECURITIES`
export const FETCH_SECURTIES_SUCCESS = `@modules/@fees/FETCH_SECURITIES_SUCCESS`
export const FETCH_SECURTIES_FAILURE = `@modules/@fees/FETCH_SECURITIES_FAILURE`
export const CLEAR_SECURITIES = `@modules/@fees/CLEAR_SECURITIES`

export const fetchSecuritiesActions = {
  request: createAction(FETCH_SECURTIES)<string>(),
  success: createAction(FETCH_SECURTIES_SUCCESS)<IAssetLookupResponse>(),
  failure: createAction(FETCH_SECURTIES_FAILURE)<Error>(),
  clear: createAction(CLEAR_SECURITIES)()
}

export const FETCH_EXISTING_OVERRIDE_BY_ID = `@modules/@fees/FETCH_EXISTING_OVERRIDE_BY_ID`
export const FETCH_EXISTING_OVERRIDE_BY_ID_SUCCESS = `@modules/@fees/FETCH_EXISTING_OVERRIDE_BY_ID_SUCCESS`
export const FETCH_EXISTING_OVERRIDE_BY_ID_FAILURE = `@modules/@fees/FETCH_EXISTING_OVERRIDE_BY_ID_FAILURE`
export const CLEAR_FETCH_EXISTING_OVERRIDE_BY_ID = `@modules/@fees/CLEAR_FETCH_EXISTING_OVERRIDE_BY_ID`

export const fetchExistingOverrideByIdActions = {
  request: createAction(FETCH_EXISTING_OVERRIDE_BY_ID)<{
    id?: string
    draftid?: string
    status?: string
  }>(),
  success: createAction(FETCH_EXISTING_OVERRIDE_BY_ID_SUCCESS)<
    IEditOverrideResponse | undefined
  >(),
  failure: createAction(FETCH_EXISTING_OVERRIDE_BY_ID_FAILURE)<Error>(),
  clear: createAction(CLEAR_FETCH_EXISTING_OVERRIDE_BY_ID)()
}

export const FETCH_FEE_OVERRIDE_LIST = `@modules/@fees/FETCH_FEE_OVERRIDE_LIST`
export const FETCH_FEE_OVERRIDE_LIST_SUCCESS = `@modules/@fees/FETCH_FEE_OVERRIDE_LIST_SUCCESS`
export const FETCH_FEE_OVERRIDE_LIST_FAILURE = `@modules/@fees/FETCH_FEE_OVERRIDE_LIST_FAILURE`
export const CLEAR_FETCH_FEE_OVERRIDE_LIST = `@modules/@fees/CLEAR_FETCH_FEE_OVERRIDE_LIST`
export const NAVIGATE_TO_OVERRIDE_FORM = `@modules/@fees/NAVIGATE_TO_OVERRIDE_FORM`
export const NAVIGATE_TO_OVERRIDE_FORM_VIEW = `@modules/@fees/NAVIGATE_TO_OVERRIDE_FORM_VIEW`

export const fetchFeeOverridesDataActions = {
  request: createAction(FETCH_FEE_OVERRIDE_LIST)<IFeeScheduleFilter>(),
  success: createAction(FETCH_FEE_OVERRIDE_LIST_SUCCESS)<
    IFeeOverrideListItem[]
  >(),
  failure: createAction(FETCH_FEE_OVERRIDE_LIST_FAILURE)<Error>(),
  clear: createAction(CLEAR_FETCH_FEE_OVERRIDE_LIST)(),
  navigateToOverrideForm: createAction(
    NAVIGATE_TO_OVERRIDE_FORM
  )<IFeeOverrideListItem>(),
  navigateToOverrideFormView: createAction(
    NAVIGATE_TO_OVERRIDE_FORM_VIEW
  )<IFeeOverrideListItem>()
}

export const DELETE_FEE_OVERRIDE = `@modules/@fees/DELETE_FEE_OVERRIDE`
export const DELETE_FEE_OVERRIDE_SUCCESS = `@modules/@fees/DELETE_FEE_OVERRIDE_SUCCESS`
export const DELETE_FEE_OVERRIDE_FAILURE = `@modules/@fees/DELETE_FEE_OVERRIDE_FAILURE`
export const CLEAR_DELETE_FEE_OVERRIDE = `@modules/@fees/CLEAR_DELETE_FEE_OVERRIDE`

export const deleteFeeOverrideActions = {
  request: createAction(DELETE_FEE_OVERRIDE)<{
    item: IFeeOverrideListItem
    selectedStatus: string
  }>(),
  success: createAction(DELETE_FEE_OVERRIDE_SUCCESS)(),
  failure: createAction(DELETE_FEE_OVERRIDE_FAILURE)<Error>(),
  clear: createAction(CLEAR_DELETE_FEE_OVERRIDE)()
}

export const FETCH_GROUP_BY_ACCOUNT_ID = `@modules/@fees/FETCH_GROUP_BY_ACCOUNT_ID`
export const FETCH_GROUP_BY_ACCOUNT_ID_SUCCESS = `@modules/@fees/FETCH_GROUP_BY_ACCOUNT_ID_SUCCESS`
export const FETCH_GROUP_BY_ACCOUNT_ID_FAILURE = `@modules/@fees/FETCH_GROUP_BY_ACCOUNT_ID_FAILURE`
export const FETCH_GROUP_BY_ACCOUNT_ID_CLEAR = `@modules/@fees/FETCH_GROUP_BY_ACCOUNT_ID_CLEAR`

export const fetchGroupByAccountIdActions = {
  request: createAction(FETCH_GROUP_BY_ACCOUNT_ID)<{
    rowId: string
    accountNo: string
  }>(),
  success: createAction(FETCH_GROUP_BY_ACCOUNT_ID_SUCCESS)<IGroupRow[]>(),
  failure: createAction(FETCH_GROUP_BY_ACCOUNT_ID_FAILURE)<IGroupRow[]>(),
  clear: createAction(FETCH_GROUP_BY_ACCOUNT_ID_CLEAR)()
}

export const INSERT_UPDATE_TIRED_AUM_FEES = `@modules/@fees/INSERT_UPDATE_TIRED_AUM_FEES`
export const INSERT_UPDATE_TIRED_AUM_FEES_SUCCESS = `@modules/@fees/INSERT_UPDATE_TIRED_AUM_FEES_SUCCESS`
export const INSERT_UPDATE_TIRED_AUM_FEES_FAILURE = `@modules/@fees/INSERT_UPDATE_TIRED_AUM_FEES_FAILURE`
export const INSERT_UPDATE_TIRED_AUM_FEES_CLEAR = `@modules/@fees/INSERT_UPDATE_TIRED_AUM_FEES_CLEAR`

export const insertUpdateTiredAumFeesActions = {
  request: createAction(INSERT_UPDATE_TIRED_AUM_FEES)<IUpdateAccountFeeModel>(),
  success: createAction(INSERT_UPDATE_TIRED_AUM_FEES_SUCCESS)<
    IUpdateAccountFeeModel | undefined
  >(),
  failure: createAction(INSERT_UPDATE_TIRED_AUM_FEES_FAILURE)<Error>(),
  clear: createAction(INSERT_UPDATE_TIRED_AUM_FEES_CLEAR)()
}

export const FETCH_EXISTING_BUILD_GROUP_DETAILS = `@modules/@fees/FETCH_EXISTING_BUILD_GROUP_DETAILS`
export const FETCH_EXISTING_BUILD_GROUP_DETAILS_SUCCESS = `@modules/@fees/FETCH_EXISTING_BUILD_GROUP_DETAILS_SUCCESS`
export const FETCH_EXISTING_BUILD_GROUP_DETAILS_FAILURE = `@modules/@fees/FETCH_EXISTING_BUILD_GROUP_DETAILS_FAILURE`
export const FETCH_EXISTING_BUILD_GROUP_DETAILS_CLEAR = `@modules/@fees/FETCH_EXISTING_BUILD_GROUP_DETAILS_CLEAR`

export const fetchExistingBuildGroupActions = {
  request: createAction(FETCH_EXISTING_BUILD_GROUP_DETAILS)<{
    id: string
    status: string
    draftId: string
  }>(),
  success: createAction(
    FETCH_EXISTING_BUILD_GROUP_DETAILS_SUCCESS
  )<IInsertUpdateFeeBuildGroupRes>(),
  failure: createAction(FETCH_EXISTING_BUILD_GROUP_DETAILS_FAILURE)<Error>(),
  clear: createAction(FETCH_EXISTING_BUILD_GROUP_DETAILS_CLEAR)()
}

export const INSERT_UPDATE_FEE_BUILD_GROUP = `@modules/@fees/INSERT_UPDATE_FEE_BUILD_GROUP`
export const INSERT_UPDATE_FEE_BUILD_GROUP_SUCCESS = `@modules/@fees/INSERT_UPDATE_FEE_BUILD_GROUP_SUCCESS`
export const INSERT_UPDATE_FEE_BUILD_GROUP_FAILURE = `@modules/@fees/INSERT_UPDATE_FEE_BUILD_GROUP_FAILURE`
export const INSERT_UPDATE_FEE_BUILD_GROUP_CLEAR = `@modules/@fees/INSERT_UPDATE_FEE_BUILD_GROUP_CLEAR`

export const insertUpdateFeeBuildGroupActions = {
  request: createAction(
    INSERT_UPDATE_FEE_BUILD_GROUP
  )<IInsertUpdateFeeBuildGroupRes>(),
  success: createAction(INSERT_UPDATE_FEE_BUILD_GROUP_SUCCESS)<
    IInsertUpdateFeeBuildGroupRes | undefined
  >(),
  failure: createAction(INSERT_UPDATE_FEE_BUILD_GROUP_FAILURE)<Error>(),
  clear: createAction(INSERT_UPDATE_FEE_BUILD_GROUP_CLEAR)()
}
export const FETCH_FEE_GROUP_LIST = `@modules/@fees/FETCH_FEE_GROUP_LIST`
export const FETCH_FEE_GROUP_LIST_SUCCESS = `@modules/@fees/FETCH_FEE_GROUP_LIST_SUCCESS`
export const FETCH_FEE_GROUP_LIST_FAILURE = `@modules/@fees/FETCH_FEE_GROUP_LIST_FAILURE`
export const CLEAR_FETCH_FEE_GROUP_LIST = `@modules/@fees/CLEAR_FETCH_FEE_GROUP_LIST`
export const NAVIGATE_TO_GROUP_FORM = `@modules/@fees/NAVIGATE_TO_GROUP_FORM`
export const NAVIGATE_TO_GROUP_FORM_VIEW = `@modules/@fees/NAVIGATE_TO_GROUP_FORM_VIEW`

export const fetchFeeGroupDataActions = {
  request: createAction(FETCH_FEE_GROUP_LIST)<string>(),
  success: createAction(FETCH_FEE_GROUP_LIST_SUCCESS)<IFeeGroupListItem[]>(),
  failure: createAction(FETCH_FEE_GROUP_LIST_FAILURE)<Error>(),
  clear: createAction(CLEAR_FETCH_FEE_GROUP_LIST)(),
  navigateToGroupForm: createAction(NAVIGATE_TO_GROUP_FORM)<{
    id: string
    status: string
    draftId: string
  }>(),
  navigateToGroupFormView: createAction(NAVIGATE_TO_GROUP_FORM_VIEW)<{
    id: string
    status: string
    draftId: string
  }>()
}
export const DELETE_FEE_GROUP = `@modules/@fees/DELETE_FEE_GROUP`
export const DELETE_FEE_GROUP_SUCCESS = `@modules/@fees/DELETE_FEE_GROUP_SUCCESS`
export const DELETE_FEE_GROUP_FAILURE = `@modules/@fees/DELETE_FEE_GROUP_FAILURE`
export const CLEAR_DELETE_FEE_GROUP = `@modules/@fees/CLEAR_DELETE_FEE_GROUP`

export const deleteFeeGroupActions = {
  request: createAction(DELETE_FEE_GROUP)<{
    item: IFeeGroupListItem
    selectedStatus: string
  }>(),
  success: createAction(DELETE_FEE_GROUP_SUCCESS)(),
  failure: createAction(DELETE_FEE_GROUP_FAILURE)<Error>(),
  clear: createAction(CLEAR_DELETE_FEE_GROUP)()
}

export const FETCH_EXISTING_TIRED_AUM_FEES_DETAILS = `@modules/@fees/FETCH_EXISTING_TIRED_AUM_FEES_DETAILS`
export const FETCH_EXISTING_TIRED_AUM_FEES_DETAILS_SUCCESS = `@modules/@fees/FETCH_EXISTING_TIRED_AUM_FEES_DETAILS_SUCCESS`
export const FETCH_EXISTING_TIRED_AUM_FEES_DETAILS_FAILURE = `@modules/@fees/FETCH_EXISTING_TIRED_AUM_FEES_DETAILS_FAILURE`
export const FETCH_EXISTING_TIRED_AUM_FEES_DETAILS_CLEAR = `@modules/@fees/FETCH_EXISTING_TIRED_AUM_FEES_DETAILS_CLEAR`

export const fetchExistingTiredAumFeesActions = {
  request: createAction(FETCH_EXISTING_TIRED_AUM_FEES_DETAILS)<{
    id: string
    status: string
    partyid: string | undefined
  }>(),
  success: createAction(
    FETCH_EXISTING_TIRED_AUM_FEES_DETAILS_SUCCESS
  )<IAccountRootModel>(),
  failure: createAction(FETCH_EXISTING_TIRED_AUM_FEES_DETAILS_FAILURE)<Error>(),
  clear: createAction(FETCH_EXISTING_TIRED_AUM_FEES_DETAILS_CLEAR)()
}

export const FETCH_ACCOUNTS_DETAILS = `@modules/@fees/FETCH_ACCOUNTS_DETAILS`
export const FETCH_ACCOUNTS_DETAILS_SUCCESS = `@modules/@fees/FETCH_ACCOUNTS_DETAILS_SUCCESS`
export const FETCH_ACCOUNTS_DETAILS_FAILURE = `@modules/@fees/FETCH_ACCOUNTS_DETAILS_FAILURE`
export const FETCH_ACCOUNTS_DETAILS_CLEAR = `@modules/@fees/FETCH_ACCOUNTS_DETAILS_CLEAR`

export const fetchAccountActions = {
  request: createAction(FETCH_ACCOUNTS_DETAILS)<{
    legalentityid: string
  }>(),
  success: createAction(FETCH_ACCOUNTS_DETAILS_SUCCESS)<IFeeAccount[]>(),
  failure: createAction(FETCH_ACCOUNTS_DETAILS_FAILURE)<Error>(),
  clear: createAction(FETCH_ACCOUNTS_DETAILS_CLEAR)()
}

export const GET_FLAT_FEE_SCHEDULE = `@modules/@fees/GET_FLAT_FEE_SCHEDULE`
export const GET_FLAT_FEE_SCHEDULE_SUCCESS = `@modules/@fees/GET_FLAT_FEE_SCHEDULE_SUCCESS`
export const GET_FLAT_FEE_SCHEDULE_FAILURE = `@modules/@fees/GET_FLAT_FEE_SCHEDULE_FAILURE`
export const GET_FLAT_FEE_SCHEDULE_CLEAR = `@modules/@fees/GET_FLAT_FEE_SCHEDULE_CLEAR`

export const getFlatFeeScheduleAction = {
  request: createAction(GET_FLAT_FEE_SCHEDULE)<{
    feeScheduleId: number
    partyUniqueId: string
  }>(),
  success: createAction(GET_FLAT_FEE_SCHEDULE_SUCCESS)<
    IFlatFeeScheduleModel[]
  >(),
  failure: createAction(GET_FLAT_FEE_SCHEDULE_FAILURE)<{ error: Error }>(),
  clear: createAction(GET_FLAT_FEE_SCHEDULE_CLEAR)()
}

export const UPDATE_FLAT_FEE_SCHEDULE = `@modules/@fees/UPDATE_FLAT_FEE_SCHEDULE`
export const UPDATE_FLAT_FEE_SCHEDULE_SUCCESS = `@modules/@fees/UPDATE_FLAT_FEE_SCHEDULE_SUCCESS`
export const UPDATE_FLAT_FEE_SCHEDULE_VALIDATION_ERROR = `@modules/@fees/UPDATE_FLAT_FEE_SCHEDULE_VALIDATION_ERROR`
export const UPDATE_FLAT_FEE_SCHEDULE_FAILURE = `@modules/@fees/UPDATE_FLAT_FEE_SCHEDULE_FAILURE`
export const UPDATE_FLAT_FEE_SCHEDULE_CLEAR = `@modules/@fees/UPDATE_FLAT_FEE_SCHEDULE_CLEAR`

export const updateFlatFeeScheduleAction = {
  request: createAction(UPDATE_FLAT_FEE_SCHEDULE)<IUpdateFeeSchedule[]>(),
  success: createAction(UPDATE_FLAT_FEE_SCHEDULE_SUCCESS)<
    IUpdateFeeScheduleResponseModel[]
  >(),
  validationError: createAction(UPDATE_FLAT_FEE_SCHEDULE_VALIDATION_ERROR)<
    IUpdateFeeScheduleResponseModel[]
  >(),
  failure: createAction(UPDATE_FLAT_FEE_SCHEDULE_FAILURE)<{ error: Error }>(),
  clear: createAction(UPDATE_FLAT_FEE_SCHEDULE_CLEAR)()
}

export const GET_ACCOUNTS_FOR_SCHEDULE = `@modules/@fees/GET_ACCOUNTS_FOR_SCHEDULE`
export const GET_ACCOUNTS_FOR_SCHEDULE_SUCCESS = `@modules/@fees/GET_ACCOUNTS_FOR_SCHEDULE_SUCCESS`
export const GET_ACCOUNTS_FOR_SCHEDULE_FAILURE = `@modules/@fees/GET_ACCOUNTS_FOR_SCHEDULE_FAILURE`
export const GET_ACCOUNTS_FOR_SCHEDULE_CLEAR = `@modules/@fees/GET_ACCOUNTS_FOR_SCHEDULE_CLEAR`

export const getAccountsForScheduleActions = {
  request: createAction(GET_ACCOUNTS_FOR_SCHEDULE)<{
    feestrutureid: number
  }>(),
  success: createAction(GET_ACCOUNTS_FOR_SCHEDULE_SUCCESS)<string[]>(),
  failure: createAction(GET_ACCOUNTS_FOR_SCHEDULE_FAILURE)<{ error: Error }>(),
  clear: createAction(GET_ACCOUNTS_FOR_SCHEDULE_CLEAR)()
}

export const scheduleFeesDataActions = {}
export type FeeListActionType = ActionType<typeof feeListActions>

export type GetFlatFeeScheduleActionType = ActionType<
  typeof getFlatFeeScheduleAction
>

export type UpdateFlatFeeScheduleAction = ActionType<
  typeof updateFlatFeeScheduleAction
>

export type FeeScheduleListActionType = ActionType<
  typeof feeScheduleListActions
>
export type FeeTypeActionType = ActionType<typeof feeTypeActions>
export type FeeDataActionType = ActionType<typeof feeDataActions>
export type FeePastChargesActionType = ActionType<typeof feePastChargesActions>
export type FeeFutureChargesActionType = ActionType<
  typeof feeFutureChargesActions
>
export type FeeFormActionType = ActionType<typeof feeFormActions>
export type ChargeFeeActionType = ActionType<typeof chargeFeeActions>
export type ExportScheduledFeeActionType = ActionType<
  typeof exportScheduledFeeActions
>
export type FeeCategoryActionType = ActionType<typeof feeCategoryTypeActions>
export type FeeNavigationActionType = ActionType<typeof feeNavigationActions>
export type SetSelectedFeePlanType = ActionType<typeof setSelectedFeePlan>
export type FeeDashboardActionType = ActionType<typeof feeDashboardActions>
export type PlatformFeeScheduleActionType = ActionType<
  typeof platformFeeScheduleActions
>
export type PlatformFeeDetailActionType = ActionType<
  typeof platformFeeDetailActions
>

export type PlatformFeeCalcPeriodActionType = ActionType<
  typeof platformFeeCalcPeriodActions
>
export type UpdatePlatformFeeScheduleActionType = ActionType<
  typeof updatePlatformFeeScheduleActions
>
export type FeeBillingDashboardActionType = ActionType<
  typeof feeBillingDashboardActions
>
export type runBillingActionType = ActionType<typeof runBillingActions>
export type setSelectedMonthYearActionType = ActionType<
  typeof setSelectedMonthYearActions
>
export type setFeeBillingSelectedMonthYearActionType = ActionType<
  typeof setFeeBillingSelectedMonthYearActions
>
export type fetchAccrualsActionType = ActionType<typeof fetchAccrualsActions>
export type exportFeePlansToExcelActionType = ActionType<
  typeof exportFeePlansToExcelActions
>
export type exportFeeBillingToExcelActionType = ActionType<
  typeof exportFeeBillingToExcelActions
>
export type PilotFetureActionTypes = ActionType<typeof pilotFetaureActions>
export type updateAccrualActionType = ActionType<typeof UpdateAccrualsActions>
export type ViewInvoiceActionType = ActionType<typeof viewInvoiceActions>
export type GenerateInvoiceActionType = ActionType<
  typeof generateInvoiceActions
>
export type CloseMonthActionTypes = ActionType<typeof closeMonthActions>
export type getInvoicesActionTypes = ActionType<typeof getInvoicesActions>
export type downloadD365ExcelActionType = ActionType<
  typeof downloadD365ExcelActions
>
export type sendForCollectionActionType = ActionType<
  typeof sendForCollectionActions
>

export type fetchFeeAssignmentDataActionsActionType = ActionType<
  typeof fetchFeeAssignmentDataActions
>

export type fetchTrustAssignmentDataActionType = ActionType<
  typeof fetchTrustAssignmentDataActions
>

export type handleTrustActionType = ActionType<typeof handleTrustActions>
export type exportToExcelGwesReportActionsType = ActionType<
  typeof exportToExcelGwesReportActions
>

export type FeesDataActionsTypes =
  | handleTrustActionType
  | GetFlatFeeScheduleActionType
  | UpdateFlatFeeScheduleAction
  | fetchFeeAssignmentDataActionsActionType
  | fetchTrustAssignmentDataActionType
  | FeeListActionType
  | FeeScheduleListActionType
  | FeeTypeActionType
  | FeeDataActionType
  | FeePastChargesActionType
  | FeeFutureChargesActionType
  | FeeFormActionType
  | ChargeFeeActionType
  | ExportScheduledFeeActionType
  | FeeCategoryActionType
  | FeeNavigationActionType
  | SetSelectedFeePlanType
  | FeeDashboardActionType
  | PlatformFeeScheduleActionType
  | PlatformFeeDetailActionType
  | PlatformFeeCalcPeriodActionType
  | UpdatePlatformFeeScheduleActionType
  | FeeBillingDashboardActionType
  | runBillingActionType
  | setSelectedMonthYearActionType
  | setFeeBillingSelectedMonthYearActionType
  | fetchAccrualsActionType
  | exportFeePlansToExcelActionType
  | exportFeeBillingToExcelActionType
  | PilotFetureActionTypes
  | updateAccrualActionType
  | ViewInvoiceActionType
  | GenerateInvoiceActionType
  | CloseMonthActionTypes
  | getInvoicesActionTypes
  | downloadD365ExcelActionType
  | sendForCollectionActionType
  | exportToExcelGwesReportActionsType
  | ActionType<typeof updateAmountActions>
  | ActionType<typeof fetchInvoiceDetailItemsActions>
  | ActionType<typeof fetchFeeCategoriesActions>
  | ActionType<typeof fetchFeeServiceTypeActions>
  | ActionType<typeof fetchInvoiceFeeTypeActions>
  | ActionType<typeof fetchFeeCollectionTypeActions>
  | ActionType<typeof fetchFeeFrequencyTypeActions>
  | ActionType<typeof fetchAgencyFeeTypeActions>
  | ActionType<typeof fetchGLAccountActions>
  | ActionType<typeof fetchDepositAccountActions>
  | ActionType<typeof fetchFeeBusinessSegmentTypeActions>
  | ActionType<typeof fetchFeeAdvisorActions>
  | ActionType<typeof fetchFeeLegalEntitiesActions>
  | ActionType<typeof createNewFeeActions>
  | ActionType<typeof updateNewFeeActions>
  | ActionType<typeof feeGLStringActions>
  | ActionType<typeof feeScheduleDataActions>
  | ActionType<typeof insertUpdateFeeOverrideActions>
  | ActionType<typeof fetchAssetSecurityDataActions>
  | ActionType<typeof fetchAssetSecurityDataPerRowActions>
  | ActionType<typeof fetchSecuritiesActions>
  | ActionType<typeof fetchExistingOverrideByIdActions>
  | ActionType<typeof insertUpdateFeeScheduleActions>
  | ActionType<typeof fetchFeeOverridesDataActions>
  | ActionType<typeof fetchFeeGroupDataActions>
  | ActionType<typeof insertUpdateFeeGroupActions>
  | ActionType<typeof deleteFeeOverrideActions>
  | ActionType<typeof deleteFeeAssignmentActions>
  | ActionType<typeof fetchGroupByAccountIdActions>
  | ActionType<typeof insertUpdateTiredAumFeesActions>
  | ActionType<typeof deleteFeeGroupActions>
  | ActionType<typeof insertUpdateFeeBuildGroupActions>
  | ActionType<typeof fetchExistingBuildGroupActions>
  | ActionType<typeof fetchExistingTiredAumFeesActions>
  | ActionType<typeof fetchAccountActions>
  | ActionType<typeof getAccountsForScheduleActions>
